import value1 from "../assets/values-1.png";
import value2 from "../assets/values-2.png";
import value3 from "../assets/values-3.png";
import angular from "../Component/AboutComponent/assets/angularjs-logo.png";
import js from "../Component/AboutComponent/assets/javascripthome.png";
import nodeJS from "../Component/AboutComponent/assets/nodejs-logo.png";
import ionic from "../Component/AboutComponent/assets/ionic.png";
import reactJs from "../Component/AboutComponent/assets/react.png";
import reactNative from "../Component/AboutComponent/assets/react-native.png";
import goLang from "../Component/AboutComponent/assets/go-home.jpg";
import mongoDB from "../Component/AboutComponent/assets/mongodb.png";
import aws from "../Component/AboutComponent/assets/amazon-web-services.png";
import sAngular from "./ServicesComponent/assets/angular.svg";
import sMobile from "./ServicesComponent/assets/ionic.png";
import sReact from "./ServicesComponent/assets/react.png";
import sMean from "./ServicesComponent/assets/mean-logo.png"

export const ourValue = [
  {
    img: value1,
    title: "Ideate & Prototype ",

    subTitle:
      "Share your ideas and vision with konnectSME. With our dedicated team get equipped to work on your project.We emphasize on testing the product with user-interaction and feedback   ",

    id: "value1",
  },
  {
    img: value2,
    title: "Lean development",
    subTitle:
      "We deliver projects on time, irrespective of its complexities. Our Developers work efficiently to meet tight schedules.",
    id: "value2",
  },
  {
    img: value3,
    title: "Support & Improvisation",

    subTitle:
      "Respond quickly to impediments and address system issues with our Technical support services. With agile,  we anticipate changes needed and ensure flexibility in the development cycle.",
    id: "value3",
  },
];



export const featurePointTwo = [
  {
    id: "f21",
    title: "Reasonable Price",
    subTitle:
      "With our flexible business models, get the benefit of cost-effectiveness. Also, with easy hiring and onboarding, ensure timely services",
    iconImg: "ri-line-chart-line",
  },
  {
    id: "f22",
    title: "Experienced Professional",
    subTitle:
      "The refined set of developers and designers hold expertise in the efficient management of projects    ",
    iconImg: "ri-stack-line",
  },
  {
    id: "f23",
    title: "Agile Development Approach",
    subTitle:
      "Our scrum teams believe in continuous improvements, continuous learning and self-organization to achieve a common goal. ",
    iconImg: "ri-brush-4-line",
  },
  {
    id: "f24",
    title: "Performance",
    subTitle:
      "Delivery of features without detailed documentation and with minimal communication overhead",
    iconImg: "ri-magic-line",
  },
  // {
  //   id: "f25",
  //   title: "Security",
  //   subTitle:
  //     "Et fuga et deserunt et enim. Dolorem architecto ratione tensa raptor marte",
  //   iconImg: "ri-command-line",
  // },
  {
    id: "f26",
    title: "Team Composition",
    subTitle:
      "People best suited to your current lifecycle: full stack developers in the beginning Vs specialists at a later stage",
    iconImg: "ri-radar-line",
  },
];

export const servicesData = [
  {
    id: "S01",
    heading: "Angular Development",
    className: "service-box blue",
    iconImg:sAngular ,
    text:"Our pool of Angular developers follows the best development practices and patterns to build single page, cross-platform applications with user-friendly features and responsive UI design."
  },
  {
    id: "S02",
    heading: "Mobile Application Development",
    className: "service-box orange",
    iconImg:sMobile ,
    text:"Create a difference in the digital landscape by building high-performance hybrid mobile application using ionic."
  },
  {
    id: "S03",
    heading: "React Js Development",
    className: "service-box green",
    iconImg: sReact,
    text:"ReactJS web app development, Customized development, Maintenance"
  },
  {
    id: "S04",
    heading: "MEAN Stack App Development  ",
  
    className: "service-box red",
    iconImg: sMean,
    text:"It helps you build fast, robust, and maintainable production web applications using MongoDB, Express, Angular, and Node.js. Mean  Stack offers Fast MVP development and scalability, Reduced development cost, Increased developer efficiency and Excellent performance"
    
  },
];


export const technology=[
  {
    id:"T01",
    heading:"Angular",
    icon:angular
  },
  {
    id:"T02",
    heading:"JavaScript",
    icon:js
  },
  {
    id:"T03",
    heading:"Node.js",
    icon:nodeJS
  },
  {
    id:"T04",
    heading:"Ionic",
    icon:ionic
  },
  {
    id:"T05",
    heading:"React Js",
    icon:reactJs
  },
  {
    id:"T06",
    heading:"React Native",
    icon:reactNative
  },
  {
    id:"T07",
    heading:"Go Lang",
    icon:goLang
  },
  {
    id:"T08",
    heading:"MongoDB",
    icon:mongoDB
  },
  {
    id:"T09",
    heading:"AWS",
    icon:aws
  }
]