import React from "react";
import aboutImage from "./assets/about.png";
import Valuesfrom from "./SubComponent/Values";
import Features from "./SubComponent/Features";

export default function About(props) {
  return (
    <div>
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gx-0">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="content">
                <h3>Who We Are</h3>
                <h2>
                  We provide dynamic and custom web and mobile applications
                  development services and solutions
                </h2>
                <p>
                  We deal with global  startups and businesses to
                  build enhance, scale and support products across all platforms
                  leveraging disurptive technologies in web and mobile
                </p>
                <div className="text-center text-lg-start">
                  <a
                    href="#contact"
                    className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img src={aboutImage} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <div className="footer" data-aos="fade-up">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center section-header">
                <p>Looking to hire resources</p>
                <ul className="list_item">
                  <li
                    className="item-list "
                    data-aos="zoom-out-left"
                    data-aos-delay="200"
                  >
                    {" "}
                    <p className="list_item_cricule"></p>Angular
                  </li>
                  <li
                    className="item-list "
                    data-aos="zoom-out-left"
                    data-aos-delay="300"
                  >
                    {" "}
                    <p className="list_item_cricule"></p>Ionic
                  </li>
                  <li
                    className="item-list "
                    data-aos="zoom-out-left"
                    data-aos-delay="400"
                  >
                    {" "}
                    <p className="list_item_cricule"></p>React Js
                  </li>
                  <li
                    className="item-list "
                    data-aos="zoom-out-left"
                    data-aos-delay="500"
                  >
                    {" "}
                    <p className="list_item_cricule"></p>Node
                  </li>
                  <li
                    className="item-list "
                    data-aos="zoom-out-left"
                    data-aos-delay="600"
                  >
                    {" "}
                    <p className="list_item_cricule"></p>Go Lang
                  </li>


                </ul>
              </div>
              <div className="about col-lg-12 ">
              <div className="text-center ">
                  <button
                    onClick={()=>props.handleModal()}
                    className="btn btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Hire  Talent</span>
                    <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Valuesfrom />

      <Features />
    </div>
  );
}
