import React from "react";
import { servicesData } from "../JsonFile";

export default function Services() {
  return (
    <div>
      <section id="services" class="services">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Technology Services</h2>
            <p>
              Scalable and robust technology services for your web and mobile
              product
            </p>
          </header>

          <div class="row gy-4">
            {servicesData.map((item, i) => {
              return (
                <div
                  class="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={100 * (1 + i) + 100}
                >
                  <div class={item.className}>
                    <i class=" icon">
                      <img src={item.iconImg} className="w-100"/>
                    </i>
                    <h3>{item.heading} </h3>
                    <p>{item.text}</p>
                    <a href="#" class="read-more">
                      <span>Read More</span> <i class="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
