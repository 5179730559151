import React from "react";
import heroImge from "./assets/hero-img.png";

export default function Home() {
  return (
    <div>
      <section id="home" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up">
              <span className="header_title">Team of professionals</span> to turn ideas into effective business
              </h1>
              <h2 data-aos="fade-up" data-aos-delay="400">
              Find experts for your application development needs
              </h2>
              <div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="600"
                  className="text-center text-lg-start"
                >
                  <a
                   href="#contact"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get In Touch</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img src={heroImge} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
