import React from "react";
import features3 from "../assets/features-3.png";
import { technology, featurePointTwo } from "../../JsonFile";

export default function Features() {
  return (
    <section id="features" className="features">
      <div className="container" data-aos="fade-up">

        <header className="section-header">
          {/* <h2>Features</h2> */}
          <p>Technology We Work With</p>
        </header>
        <div className="row d-flex align-items-center">
          <div className="col-lg-6">
            <img src={features3} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
            <div className="row align-self-center gy-4">
              {technology.map((item, i) => (
                <div className="col-md-6" key={item.id}>
                  <div
                    className="feature-box d-flex align-items-center"
                    data-aos="zoom-out"
                    data-aos-delay={100 * (1 + i) + 100}
                  >
                    <i className="bi ">
                     <img src={item.icon} className="w-100"/>
                    </i>
                    <h3>{item.heading} </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>




        <div className="row feature-icons" data-aos="fade-up">
          <h3>Why Choose Us</h3>
          <div className="row ">
            <div
              className=" text-center"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              {/* <img src={features3} className="img-fluid p-4" alt="" /> */}
            </div>
            <div className="col-xl-12 d-flex content">
              <div className="row align-self-center gy-4">
                {featurePointTwo.map((item, i) => (
                  <div
                    key={item.id}
                    className="col-md-6 icon-box"
                    data-aos="fade-up"
                    data-aos-delay={100 * i}
                  >
                    <i className={item.iconImg}></i>
                    <div>
                      <h4>{item.title} </h4>
                      <p>{item.subTitle}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
