import React, { useState } from "react";
import logo from "./assets/logo.png";
export default function Navbar(props) {
  const [currentActive, setCurrentActive] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);

  const [menu, setMenu] = useState([
    {
      href: "#home",
      active: true,
      id: "home",
      title: "Home",
    },
    // {
    //   href: "#about",
    //   active: false,
    //   id: "about",
    //   title: "About",
    // },
    {
      href: "#services",
      active: false,
      id: "services",
      title: "Services",
    },
    {
      href: "#contact",
      active: false,
      id: "contact",
      title: "Contact Us",
    },
    // {
    //   href: "",
    //   active: false,
    //   id: "currentOpening",
    //   title: "Current Openings",
    // }
  ]);
  const handleSetCurrentActive = (currentActive) => {
    setCurrentActive(currentActive);
    if(mobileMenu){
      setMobileMenu(false);
    }
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  const onPress = (active) => {
    let newMenu = [...menu];
    newMenu[currentActive - 1].active = false;
    newMenu[active].active = true;
    setMenu(newMenu);
    setCurrentActive(null);
  };

  return (
    <header
      id="header"
      class={
        props.headerBox
          ? "header fixed-top header-scrolled"
          : "header fixed-top "
      }
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <div
          className="logo d-flex align-items-center"
          onClick={() => props.handleTop()}
        >
          <img src={logo} alt="" />
          {/* <span>KonnectSME</span> */}
        </div>

        <nav
          id="navbar"
          className={mobileMenu ? "navbar navbar-mobile" : "navbar"}
        >
          <ul>
            {menu.map((item, index) => {
              if (item.active && !currentActive) {
                handleSetCurrentActive(index + 1);
              }
              return (
                <li key={item.id}>
                  <a
                    onClick={() => onPress(index)}
                    href={item.href}
                    className={
                      item.active
                        ? "nav-link scrollto active"
                        : "nav-link scrollto"
                    }
                  >
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
          <i
            className={
              mobileMenu
                ? "bi mobile-nav-toggle bi-x"
                : "bi bi-list mobile-nav-toggle"
            }
            onClick={() => handleMobileMenu(mobileMenu)}
          ></i>
        </nav>
      </div>
    </header>
  );
}
