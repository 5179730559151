import React from "react";
import { useFormik } from "formik";

const initialValue = {
  name: "",
  email: "",
  subject: "",
  message: "",
};
const handleValidate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = " Required *";
  }
  if (!values.email) {
    errors.email = "Required *";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.subject) {
    errors.subject = " Required *";
  }
  if (!values.message) {
    errors.message = " Required *";
  }
  return errors;
};

export default function Contact() {
  const contactForm = useFormik({
    initialValues: initialValue,
    validate: handleValidate,
    onSubmit: (values) => {
      // console.log(values);
      contactForm.resetForm();
    },
  });
  return (
    <div>
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            {/* <h2>Contact</h2> */}
            <p>Contact Us</p>
          </header>

          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-geo-alt"></i>
                    <h3>Address</h3>
                    <p>
                      59, 1st floor, 1st Avenue,
                      <br />
                      Teachers colony, HSR layout Bangalore 560034
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>
                      +91-98872 11412
                      <br />
                      +91-96942 19254
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>
                      admin@konnectsme.in
                      <br />
                      hr@konnectsme.in
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-clock"></i>
                    <h3>Open Hours</h3>
                    <p>
                      Monday - Friday
                      <br />
                      9:00AM - 06:30PM
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <form
                onSubmit={contactForm.handleSubmit}
                className="php-email-form"
              >
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className=""
                      className={
                        contactForm.touched.name && contactForm.errors.name
                          ? " error__messages"
                          : "form-control"
                      }
                      placeholder="Your Name"
                      onChange={contactForm.handleChange}
                      value={contactForm.values.name}
                    />
                   
                  </div>

                  <div className="col-md-6 ">
                    <input
                      type="email"
                      className={
                        contactForm.touched.email&& contactForm.errors.email
                          ? "form-control error__messages"
                          : "form-control"
                      }
                      name="email"
                      placeholder="Your Email"
                      onChange={contactForm.handleChange}
                      value={contactForm.values.email}
                    />
                  </div>

                  <div className="col-md-12">
                    <input
                      type="text"
                      className={
                        contactForm.touched.subject&&contactForm.errors.subject
                          ? "form-control error__messages"
                          : "form-control"
                      }
                      name="subject"
                      placeholder="Subject"
                      onChange={contactForm.handleChange}
                      value={contactForm.values.subject}
                    />
                  </div>

                  <div className="col-md-12">
                    <textarea
                      className={
                        contactForm.touched.message&&contactForm.errors.message
                          ? "form-control error__messages"
                          : "form-control"
                      }
                      name="message"
                      rows="6"
                      placeholder="Message"
                      onChange={contactForm.handleChange}
                      value={contactForm.values.message}
                    ></textarea>
                  </div>

                  <div className="col-md-12 text-center">
                    {/* <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div> */}

                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
