import React, { useState } from "react";
import { ourValue } from "../../JsonFile";

export default function Values() {
  const [state, setstate] = useState(ourValue);
  return (
    <section id="values" className="values">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          {/* <h2>Our Expertise</h2> */}
          <p>What We Do</p>
        </header>

        <div className="row">
          {state.map((item,i) => {
            return (
              <div className="col-lg-4" key={item.id}>
                <div className="box" data-aos="fade-up" data-aos-delay={100*(i+1)}>
                  <img src={item.img} className="img-fluid" alt="" />
                  <h3>{item.title} </h3>
                  <p className="text-justify">{item.subTitle}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
