import Navbar from "./Component/Navbar/Navbar";
import React, { useState } from "react";
import "./App.css";
import Home from "./Component/HomeComponent/Home";
import About from "./Component/AboutComponent/About";
import Services from "./Component/ServicesComponent/Services";
import Contact from "./Component/ContactComponent/Contact";
import Footer from "./Component/FooterComponent/Footer";
import Modal from "./Component/ModalComponent/Modal"
import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  AOS.init();
  const [visible, setVisible] = useState(false);
  const [modalShow,setModal] = useState(false)
  const toggleVisible = (e) => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 200) {
      setVisible(true);
    } else if (scrolled <= 200) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", (e) => toggleVisible(e));
  const GoTotop = () => {
    window.scroll(0, 0);
  };
  const handleModal=()=>{
    setModal(true)
  }
  const handleCloseModal=()=>{
    setModal(false)
  }
  return (
    
    <div className="App ">
     
      
      <Navbar headerBox={visible} handleTop={GoTotop} />
     { modalShow&&<Modal handleCloseModal ={handleCloseModal} />}
      <Home id="home" />
      <main id="main">
        <About id="about" handleModal={handleModal} />
        <Services id="services" />
        <Contact id="contact" />
      </main>

      <Footer handleTop={GoTotop} />

      <div
        onClick={() => GoTotop()}
        class="back-to-top d-flex align-items-center justify-content-center btn"
        style={visible ? { opacity: 1, visibility: "visible" } : { opacity: 0 }}
      >
        <i class="bi bi-arrow-up-short"></i>
      </div>
      
    </div>
  );
}

export default App;
