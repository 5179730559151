import React from "react";
import logo from "../Navbar/assets/logo.png";

export default function Footer(props) {
  return (
    <div>
      <footer id="footer" className="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center section-header">
                <p>How can we help?</p>

                <p className="item-list_text">
                  konnectSME provides development solutions for every industry
                  and their requirements
                </p>
              </div>
              <div className="about col-lg-12 ">
                <div className="text-center ">
                  <a
                    href="#contact"
                    className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Contact Us</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5 col-md-12 footer-info">
                <div
                  onClick={() => props.handleTop()}
                  className="logo d-flex align-items-center"
                >
                  <img src={logo} alt="" />
                  {/* <span>KonnectSME</span> */}
                </div>
                <p>
                  The Ideation of KonnectSME has come up when we saw there is a
                  massive gap between businesses and missing opportunities lack
                  of robust technology, KonnectSME has a strong belief with the
                  state of the art technology we are on a mission to create an
                  SME ecosystem that is resilient and self-reliant.
                </p>
                <div className="social-links mt-3">
                  <a href="#" className="twitter">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="https://www.facebook.com/konnectsme" className="facebook" target="_blank">
                    <i className="bi bi-facebook"></i>
                  </a>
                  {/* <a href="#" className="instagram">
                    <i className="bi bi-instagram"></i>
                  </a> */}
                  <a href="https://www.linkedin.com/company/67089811/admin/" className="linkedin" target="_blank">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Airsme Technology Private Limited </span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>
    </div>
  );
}
