import React from "react";
import crossIcon from "./assets/cross.svg";
import "./Modal.css";
import { useFormik, Field } from "formik";

const initialValue = {
  experience: "",
  numberOfResource: "",
  name: "",
  mobile: "",
  email: "",
  message: "",
  number: "",
};
const handleValidate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = " Required *";
  }
  if (
    !values.email ||
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Required *";
  }
  if (!values.mobile || !/^[7-9][0-9]{9}$/i.test(values.mobile)) {
    errors.mobile = " Required *";
  }
  if (!values.numberOfResource || !/^[0-9]+$/i.test(values.numberOfResource)) {
    errors.numberOfResource = "Required *";
  }
  if (!values.experience) {
    errors.experience = "Required *";
  }
  if (!values.message) {
    errors.message = " Required *";
  }

  return errors;
};

export default function Modal(props) {
  const hireForm = useFormik({
    initialValues: initialValue,
    validate: handleValidate,
    onSubmit: (values) => {
      // console.log(values);
      hireForm.resetForm();
      props.handleCloseModal();
    },
  });
  return (
    <div className="modal">
      {/* <li className="bi mobile-nav-toggle bi-x"></li> */}
      <div class="modal__content">
        <div className="modal__header d-flex">
          <p>Hire Talent</p>
          <div
            className="class__close__img"
            onClick={() => props.handleCloseModal()}
          >
            <img src={crossIcon} />
          </div>
        </div>
        <form onSubmit={hireForm.handleSubmit} className="php-email-form">
          <div className="row gy-4">
            <div className="col-md-6">
              <input
                type="text"
                name="name"
                className=""
                className={
                  hireForm.touched.name && hireForm.errors.name
                    ? " error__messages"
                    : "form-control"
                }
                placeholder="Your Name"
                onChange={hireForm.handleChange}
                value={hireForm.values.name}
              />
            </div>

            <div className="col-md-6 ">
              <input
                type="email"
                className={
                  hireForm.touched.email && hireForm.errors.email
                    ? " error__messages"
                    : "form-control"
                }
                name="email"
                placeholder="Your Email"
                onChange={hireForm.handleChange}
                value={hireForm.values.email}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="mobile"
                className=""
                className={
                  hireForm.touched.mobile && hireForm.errors.mobile
                    ? " error__messages"
                    : "form-control"
                }
                placeholder="Mobile Number"
                maxLength="10"
                onChange={hireForm.handleChange}
                value={hireForm.values.mobile}
              />
            </div>
            <div className="col-md-6">
              <input
                type="text"
                name="numberOfResource"
                className=""
                className={
                  hireForm.touched.numberOfResource &&
                  hireForm.errors.numberOfResource
                    ? " error__messages"
                    : "form-control"
                }
                placeholder="No of Resource"
                onChange={hireForm.handleChange}
                value={hireForm.values.numberOfResource}
              />
            </div>
            <div className="col-md-12">
              <select
                name="experience"
                as="select"
                className={
                  hireForm.touched.experience && hireForm.errors.experience
                    ? " error__messages"
                    : "form-control"
                }
                onBlur={hireForm.handleBlur}
                onChange={hireForm.handleChange}
                value={hireForm.values.experience}
              >
                <option value="">Choose the Experience (In Yrs)</option>
                <option value="1" label="1" />
                <option value="2" label="2" />
                <option value="3" label="3" />
                <option value="4" label="4" />
                <option value="5" label="5" />
                <option value="6" label="6" />
                <option value="6<" label="6<" />
              </select>
            </div>

            <div className="col-md-12">
              <textarea
                className={
                  hireForm.touched.message && hireForm.errors.message
                    ? "form-control error__messages"
                    : "form-control"
                }
                name="message"
                rows="6"
                placeholder="Message"
                onChange={hireForm.handleChange}
                onBlur={hireForm.handleBlur}
                value={hireForm.values.message}
              ></textarea>
            </div>

            <div className="col-md-12 text-center">
              <button type="submit" className="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
